import { Routes } from '@angular/router';
import { INavGroup } from 'bp-framework/dist/common/common.interface';
import { MAIN_ROUTE_PATHS } from 'src/app/shared/models/routing/routing.const';

export const ALL_ROUTES_FOR_BETPLATFORM_SURFPOINT: Routes = [
  //#region Main
  {
    path: MAIN_ROUTE_PATHS.login,
    loadComponent: () => import('../../../pages/auth/login/login.component').then(m => m.LoginComponent)
  },
  //#endregion Main
  //#region Surfpoint
  {
    path: 'surfpoint',
    children: [
      {
        path: 'landing',
        loadComponent: () => import('../../../pages/surfpoint/pages/surfpoint-landing/surfpoint-landing.component').then(m => m.SurfpointLandingComponent)
      },
      {
        path: 'lobby',
        loadComponent: () => import('../../../pages/surfpoint/pages/surfpoint-lobby/surfpoint-lobby.component').then(m => m.SurfpointLobbyComponent)
      },
      {
        path: 'casino',
        loadComponent: () => import('../../../pages/surfpoint/pages/surfpoint-casino/surfpoint-casino.component').then(m => m.SurfpointCasinoComponent)
      },
      {
        path: 'casino/:gameId',
        loadComponent: () => import('../../../pages/surfpoint/pages/surfpoint-play-game/surfpoint-play-game.component').then(m => m.SurfpointPlayGameComponent)
      },
      {
        path: '',
        redirectTo: 'landing',
        pathMatch: 'full'
      }
    ]
  },
  //#endregion Surfpoint
  {
    path: MAIN_ROUTE_PATHS.notFound,
    loadComponent: () => import('../../../pages/not-found/not-found.component').then(m => m.NotFoundComponent)
  },
  {
    path: 'theme',
    loadComponent: () => import('../../../pages/theme-testing/theme-testing.component').then(m => m.ThemeTestingComponent)
  },
  {
    path: '',
    redirectTo: 'surfpoint',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: MAIN_ROUTE_PATHS.notFound
  }
];

export const SIDEMENU_NAV_GROUPS_FOR_SURFPOINT: Partial<INavGroup>[] = [
  {
    path: MAIN_ROUTE_PATHS.home,
    label: 'Home',
    t9nKey: '',
    sortOrder: 0,
    icon: 'ph-house',
    children: []
  },
  {
    path: MAIN_ROUTE_PATHS.casino,
    label: 'Casino',
    t9nKey: '',
    sortOrder: 3,
    icon: 'ph-club'
  }
];
