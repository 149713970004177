import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { INavGroup } from 'bp-framework/dist/common/common.interface';
import { SIDEMENU_NAV_GROUPS } from 'src/app/shared/models/routing/routing.const';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { IonicModule, MenuController } from '@ionic/angular';
import { filter } from 'rxjs';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { AuthService, IEnvironmentConfig, PROJECT_ENV_CONFIG_TOKEN, ThemeToggleComponent } from 'bp-angular-library';
import { EnvAdapterService } from 'src/app/core/env-specific/env-adapter.service';

@Component({
  selector: 'app-sidemenu',
  standalone: true,
  imports: [CommonModule, IonicModule, RouterLink, RouterLinkActive, ThemeToggleComponent],
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {
  public projectConfig: IEnvironmentConfig<any> = inject<IEnvironmentConfig<any>>(PROJECT_ENV_CONFIG_TOKEN);
  private destroyRef: DestroyRef = inject(DestroyRef);

  public sideMenuGroups: Partial<INavGroup>[] = SIDEMENU_NAV_GROUPS;

  constructor(
    private menuCtrl: MenuController,
    public authService: AuthService,
    private envAdapterService: EnvAdapterService,
    private router: Router
  ) {}

  //#region lifecycle hooks
  ngOnInit(): void {
    this.subscribeToRouterEvents();
    this.sideMenuGroups = this.envAdapterService.buildSidemenuRoutes();
  }
  //#endregion

  private subscribeToRouterEvents(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe((event: any): void => this.resolveCurrentSegment(event?.urlAfterRedirects));
  }

  private resolveCurrentSegment(urlAfterRedirect: string): void {
    let matchingNavItem: Partial<INavGroup> | null = null;

    this.sideMenuGroups?.forEach((item: Partial<INavGroup>) => {
      if (item.path && urlAfterRedirect.includes(item.path)) {
        matchingNavItem = item;
        matchingNavItem.expanded = Array.isArray(item?.children) && item?.children?.length > 0;
      }
    });
  }

  public closeMenu(): void {
    this.menuCtrl.close('primary-sidemenu');
  }
}
