import { Injectable, inject } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthService, IEnvironmentConfig, PROJECT_ENV_CONFIG_TOKEN, StorageService } from 'bp-angular-library';
import { ALL_ROUTES_FOR_1X2TEAM, SIDEMENU_NAV_GROUPS_FOR_1X2TEAM } from './1x2team/routes.1x2team';
import { ALL_ROUTES_FOR_BETPLATFORM, SIDEMENU_NAV_GROUPS_FOR_BETPLATFORM } from './betplatform/routes.betplatform';
import { ALL_ROUTES_FOR_BETPLATFORM_SURFPOINT } from './betplatform/routes.surfpoint';
import { IListItem, INavGroup } from 'bp-framework/dist/common/common.interface';
import { Adapter1x2teamService } from './1x2team/adapter-1x2team.service';
import { AdapterBetPlatformService } from './betplatform/adapter-betplatform.service';
import { ICasinoGameDetails, ICasinoGameLaunchDetails } from 'bp-framework/dist/casino/casino.interface';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';
import { ALL_ROUTES_FOR_1X2TEAM_SURFPOINT } from './1x2team/routes.surfpoint';
import { ILoginCredentials } from 'bp-framework/dist/auth/auth.interface';
import { STORAGE_KEYS } from 'bp-framework/dist/storage/storage.const';
import { CASINO_CATEGORIES_BETPLATFORM } from 'bp-framework/dist/env-specific/betplatform/casino/casino.const';

@Injectable({
  providedIn: 'root'
})
export class EnvAdapterService {
  private projectConfig: IEnvironmentConfig<any> = inject<IEnvironmentConfig<any>>(PROJECT_ENV_CONFIG_TOKEN);

  constructor(
    private adapter1x2teamService: Adapter1x2teamService,
    private adapterBetPlatformService: AdapterBetPlatformService,
    private authService: AuthService,
    private storageService: StorageService
  ) {}

  public buildAllRoutes(): Routes {
    if (this.projectConfig?.api?.provider === '1x2team') {
      if (this.projectConfig?.features?.frontendMode === 'surfpoint') {
        return ALL_ROUTES_FOR_1X2TEAM_SURFPOINT;
      } else {
        return ALL_ROUTES_FOR_1X2TEAM;
      }
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      if (this.projectConfig?.features?.frontendMode === 'surfpoint') {
        return ALL_ROUTES_FOR_BETPLATFORM_SURFPOINT;
      } else {
        return ALL_ROUTES_FOR_BETPLATFORM;
      }
    } else {
      return [];
    }
  }

  public buildSidemenuRoutes(): Partial<INavGroup>[] {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return SIDEMENU_NAV_GROUPS_FOR_1X2TEAM;
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return SIDEMENU_NAV_GROUPS_FOR_BETPLATFORM;
    } else {
      return [];
    }
  }

  public async loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null> {
    if (this.projectConfig?.features?.storeCredentials === true && username && password) {
      const loginCredentials: ILoginCredentials = {
        username,
        password
      };

      await this.storageService.setLocalItem(STORAGE_KEYS.userCredentials, loginCredentials);
    }

    if (this.projectConfig?.api?.provider === '1x2team') {
      return this.adapter1x2teamService.loginWithUsernameAndPassword(username, password);
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.loginWithUsernameAndPassword(username, password);
    } else {
      return Promise.resolve(null);
    }
  }

  public async getUserProfile(): Promise<Partial<IUserDetails> | null> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return this.adapter1x2teamService.getUserProfile();
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.getUserProfile();
    } else {
      return Promise.resolve(null);
    }
  }

  public async updateUserWithProfileData(): Promise<Partial<IUserDetails> | null> {
    const freshData: Partial<IUserDetails> | null = await this.getUserProfile();
    await this.authService.userDetailsChanged(freshData);
    return this.authService.user$.value;
  }

  public async getCasinoGames(): Promise<ICasinoGameDetails<any, any>[]> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      if (this.projectConfig?.features?.frontendMode === 'surfpoint') {
        return this.adapter1x2teamService.getAllCasinoGamesForSurfpoint();
      } else {
        return this.adapter1x2teamService.getAllCasinoGames();
      }
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.getAllCasinoGames();
    } else {
      return Promise.resolve([]);
    }
  }

  public async getDetailsToLaunchGame(gameId: number): Promise<ICasinoGameLaunchDetails> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return this.adapter1x2teamService.getDetailsToLaunchGame(gameId);
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return this.adapterBetPlatformService.getDetailsToLaunchGame(gameId);
    } else {
      return Promise.resolve({} as any);
    }
  }

  public async getCasinoCategories(): Promise<Partial<IListItem<number>>[]> {
    if (this.projectConfig?.api?.provider === '1x2team') {
      return this.adapter1x2teamService.getCasinoCategories();
    } else if (this.projectConfig?.api?.provider === 'betplatform') {
      return Promise.resolve(CASINO_CATEGORIES_BETPLATFORM);
    } else {
      return Promise.resolve([]);
    }
  }
}
