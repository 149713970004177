import { Routes } from '@angular/router';
import { INavGroup, INavigationItem } from 'bp-framework/dist/common/common.interface';
import { MAIN_ROUTE_PATHS, PLAYER_ROUTE_CHILD_SEGMENTS, PLAYER_ROUTE_ROOT_SEGMENT } from 'src/app/shared/models/routing/routing.const';

export const ALL_ROUTES_FOR_1X2TEAM: Routes = [
  //#region Main
  {
    path: MAIN_ROUTE_PATHS.login,
    loadComponent: () => import('../../../pages/auth/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: MAIN_ROUTE_PATHS.landing,
    loadComponent: () => import('../../../pages/landing/landing.component').then(m => m.LandingComponent)
  },
  {
    path: MAIN_ROUTE_PATHS.betting,
    loadComponent: () => import('../../../pages/betting/pages/betting/betting.component').then(m => m.BettingComponent)
  },
  {
    path: MAIN_ROUTE_PATHS.bettingLive,
    loadComponent: () => import('../../../pages/betting/pages/betting-live/betting-live.component').then(m => m.BettingLiveComponent)
  },
  {
    path: MAIN_ROUTE_PATHS.casino,
    loadChildren: () => import('../../../pages/casino/casino.routes').then(r => r.CASINO_ROUTES)
  },
  {
    path: MAIN_ROUTE_PATHS.casinoLive,
    loadComponent: () => import('../../../pages/casino/pages/casino-live/casino-live.component').then(m => m.CasinoLiveComponent)
  },
  {
    path: MAIN_ROUTE_PATHS.virtualSports,
    loadComponent: () => import('../../../pages/virtual/pages/virtual-sports/virtual-sports.component').then(m => m.VirtualSportsComponent)
  },
  {
    path: MAIN_ROUTE_PATHS.virtualGames,
    loadComponent: () => import('../../../pages/virtual/pages/virtual-games/virtual-games.component').then(m => m.VirtualGamesComponent)
  },
  //#endregion Main
  //#region Player
  {
    path: `${PLAYER_ROUTE_ROOT_SEGMENT}`,
    loadComponent: () => import('../../../pages/player/player.component').then(m => m.PlayerComponent),
    children: [
      {
        path: PLAYER_ROUTE_CHILD_SEGMENTS.dashboard,
        loadComponent: () => import('../../../pages/player/dashboard/dashboard.component').then(m => m.DashboardComponent)
      },
      {
        path: PLAYER_ROUTE_CHILD_SEGMENTS.betSlips,
        loadComponent: () => import('../../../pages/player/bet-slips/bet-slips.component').then(m => m.BetSlipsComponent)
      },
      {
        path: PLAYER_ROUTE_CHILD_SEGMENTS.transactions,
        loadComponent: () => import('../../../pages/player/transactions/transactions.component').then(m => m.TransactionsComponent)
      },
      {
        path: PLAYER_ROUTE_CHILD_SEGMENTS.details,
        loadComponent: () => import('../../../pages/player/details/details.component').then(m => m.DetailsComponent)
      },
      {
        path: '',
        redirectTo: PLAYER_ROUTE_CHILD_SEGMENTS.dashboard,
        pathMatch: 'full'
      }
    ]
  },
  //#endregion Player
  {
    path: MAIN_ROUTE_PATHS.notFound,
    loadComponent: () => import('../../../pages/not-found/not-found.component').then(m => m.NotFoundComponent)
  },
  {
    path: 'theme',
    loadComponent: () => import('../../../pages/theme-testing/theme-testing.component').then(m => m.ThemeTestingComponent)
  },
  {
    path: '',
    redirectTo: MAIN_ROUTE_PATHS.landing, // Default page
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: MAIN_ROUTE_PATHS.notFound
  }
];

export const MAIN_HEADER_LINKS_FOR_1X2TEAM: Partial<INavigationItem<number>>[] = [
  { labelT9nKey: 'pages.betting.title', value: 1, routePath: `/${MAIN_ROUTE_PATHS.betting}` },
  { labelT9nKey: 'pages.bettingLive.title', value: 2, routePath: `/${MAIN_ROUTE_PATHS.bettingLive}` },
  { labelT9nKey: 'pages.casino.title', value: 3, routePath: `/${MAIN_ROUTE_PATHS.casino}` },
  { labelT9nKey: 'pages.casinoLive.title', value: 4, routePath: `/${MAIN_ROUTE_PATHS.casinoLive}` },
  { labelT9nKey: 'pages.virtualSports.title', value: 5, routePath: `/${MAIN_ROUTE_PATHS.virtualSports}` },
  { labelT9nKey: 'pages.virtualGames.title', value: 7, routePath: `/${MAIN_ROUTE_PATHS.virtualGames}` }
];

export const SIDEMENU_NAV_GROUPS_FOR_1X2TEAM: Partial<INavGroup>[] = [
  {
    path: MAIN_ROUTE_PATHS.home,
    label: 'Home',
    t9nKey: '',
    sortOrder: 0,
    icon: 'ph-house',
    children: []
  },
  {
    path: MAIN_ROUTE_PATHS.betting,
    label: 'Betting',
    t9nKey: '',
    sortOrder: 1,
    icon: 'ph-soccer-ball'
  },
  {
    path: MAIN_ROUTE_PATHS.bettingLive,
    label: 'Live Betting',
    t9nKey: '',
    sortOrder: 2,
    icon: 'ph-soccer-ball'
  },
  {
    path: MAIN_ROUTE_PATHS.casino,
    label: 'Casino',
    t9nKey: '',
    sortOrder: 3,
    icon: 'ph-club'
  },
  {
    path: MAIN_ROUTE_PATHS.casinoLive,
    label: 'Live Casino',
    t9nKey: '',
    sortOrder: 4,
    icon: 'ph-club'
  }
];
