import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicModule } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { appInitFactory } from './core/initializer/initializers';
import { AuthService, StorageService, PROJECT_ENV_CONFIG_TOKEN } from 'bp-angular-library';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { SidemenuComponent } from './features/sidemenu/sidemenu.component';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { EnvAdapterService } from './core/env-specific/env-adapter.service';
import { AuthenticationInterceptor } from './core/interceptors/auth/authentication.interceptor';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    IonicModule.forRoot({
      mode: 'md',
      rippleEffect: false,
      swipeBackEnabled: false
    }),
    IonicStorageModule.forRoot({
      name: '__appdb',
      driverOrder: [Drivers.LocalStorage]
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SidemenuComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitFactory,
      deps: [Router, StorageService, AuthService, TranslateService, EnvAdapterService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    { provide: PROJECT_ENV_CONFIG_TOKEN, useValue: environment },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
