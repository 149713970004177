<ion-header>
  <ion-toolbar color="main-header-bg" class="ion-text-center">
    <a class="has-logo" href="">
      <img class="is-logo" src="./env-assets/images/main-logo.svg" alt="Logo" loading="lazy" />
    </a>
  </ion-toolbar>
</ion-header>
<ion-content color="main-header-bg">
  <div>Env: {{ projectConfig.environmentName }}</div>
  <div>Project name:: {{ projectConfig.projectName }}</div>
  <nav>
    <ul>
      <ng-container *ngFor="let group of sideMenuGroups">
        <!-- Items without children -->
        <ng-container *ngIf="group?.children?.length! <= 0">
          <li [routerLink]="'/' + group?.path" routerLinkActive="active" ariaCurrentWhenActive="page" (click)="closeMenu()">
            <a ariaCurrentWhenActive="page">
              <span aria-hidden="true" class="ph" [ngClass]="group?.icon"></span>
              {{ group?.label }} <span aria-hidden="true" class="ph ph-caret-right"></span
            ></a>
          </li>
        </ng-container>
        <!-- Items with nested children -->
        <ng-container *ngIf="group?.children?.length! > 0">
          <li (click)="group.expanded = !group.expanded">
            <a ariaCurrentWhenActive="page">
              <span aria-hidden="true" class="ph" [ngClass]="group?.icon"></span> {{ group?.label }}
              <span aria-hidden="true" class="ph" [ngClass]="group?.expanded ? 'ph-caret-up' : 'ph-caret-down'"></span>
            </a>
          </li>
          <ng-container *ngIf="group?.expanded">
            <ul class="is-child-list">
              <li
                *ngFor="let item of group?.children"
                [routerLink]="'/' + group?.path + '/' + item?.path"
                routerLinkActive="active"
                ariaCurrentWhenActive="page"
                (click)="closeMenu()">
                <a><span aria-hidden="true" class="ph" [ngClass]="item?.icon"></span> {{ item?.label }}</a>
              </li>
            </ul>
          </ng-container>
        </ng-container>
      </ng-container>
    </ul>
  </nav></ion-content
>
<ion-footer>
  <ion-toolbar color="main-header-bg">
    <lib-theme-toggle class="branding-bg"></lib-theme-toggle>
  </ion-toolbar>
</ion-footer>
