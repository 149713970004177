import { Injectable, inject } from '@angular/core';
import { AuthService, BpCasinoApiService, BpCoreApiService } from 'bp-angular-library';
import { transformLoginDataToUserDetails, transformProfileDataToUserDetails, updateUserDetailsWithProfileData } from 'bp-framework/dist/env-specific/betplatform/user/user.mappers';
import { IGetProfilePayload, ILoginPayload } from 'bp-framework/dist/env-specific/betplatform/user/user.interface';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';
import { ICasinoGameDetails, ICasinoGameLaunchDetails } from 'bp-framework/dist/casino/casino.interface';
import { CasinoGame, CasinoLaunchDetails, CasinoPayload } from 'bp-framework/dist/env-specific/betplatform/casino/casino.interface';
import { mapCasinoGames } from 'bp-framework/dist/env-specific/betplatform/casino/casino.mappers';

@Injectable({
  providedIn: 'root'
})
export class AdapterBetPlatformService {
  private bpCoreApiService: BpCoreApiService = inject(BpCoreApiService);
  private bpCasinoApiService: BpCasinoApiService = inject(BpCasinoApiService);
  private authService: AuthService = inject(AuthService);

  public async loginWithUsernameAndPassword(username: string, password: string): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const loginPayload: ILoginPayload | null = await this.bpCoreApiService.authenticatePlayerWithUsernameAndPassword(username, password);

        if (!loginPayload?.token) {
          return reject(new Error('Failed to login! Check your credentials'));
        }

        await this.authService.userAuthChanged(transformLoginDataToUserDetails(loginPayload));

        const getProfilePayload: IGetProfilePayload = await this.bpCoreApiService.getProfile();
        const currentDetails: Partial<IUserDetails> | null = this.authService.user$.value;

        const mergedValue: Partial<IUserDetails> | null = updateUserDetailsWithProfileData(currentDetails, getProfilePayload);

        await this.authService.userAuthChanged(mergedValue);

        resolve(mergedValue);
      } catch (error) {
        return reject(new Error('Failed to login. Please check your username or password or try again later!'));
      }
    });
  }

  public async getUserProfile(): Promise<Partial<IUserDetails> | null> {
    return new Promise<Partial<IUserDetails> | null>(async (resolve, reject) => {
      try {
        const userProfile: IGetProfilePayload = await this.bpCoreApiService.getProfile();
        resolve(userProfile ? transformProfileDataToUserDetails(userProfile) : null);
      } catch (error) {
        return reject(new Error('Failed retrieve of user profile data'));
      }
    });
  }

  public async getAllCasinoGames(): Promise<ICasinoGameDetails<any, any>[]> {
    return new Promise<ICasinoGameDetails<any, any>[]>(async (resolve, reject) => {
      try {
        const response: CasinoPayload<CasinoGame> | null = await this.bpCasinoApiService.getAllCasinoGames();
        const mapped: ICasinoGameDetails<any, any>[] = response?.results && Array.isArray(response?.results) ? mapCasinoGames(response.results) : [];
        resolve(mapped);
      } catch (error) {
        return reject(new Error('Failed retrieve the list of casino games'));
      }
    });
  }

  public async getDetailsToLaunchGame(gameId: number): Promise<ICasinoGameLaunchDetails> {
    return new Promise<ICasinoGameLaunchDetails>(async (resolve, reject) => {
      try {
        const response: CasinoLaunchDetails = await this.bpCasinoApiService.getCasinoGamePlayUrl(gameId);
        resolve({ url: response?.launchUrl, token: response?.token } as ICasinoGameLaunchDetails);
      } catch (error) {
        return reject(new Error('Failed retrieve the game launch url'));
      }
    });
  }

  public async getSingleCasinoGameToPlay(gameId: number): Promise<ICasinoGameDetails<any, any>[]> {
    return new Promise<ICasinoGameDetails<any, any>[]>(async (resolve, reject) => {
      try {
        const response: any = await this.bpCasinoApiService.getCasinoGamePlayUrl(gameId);
        console.log('getSingleCasinoGameToPlay');
        console.log(response);
        resolve(response);
      } catch (error) {
        return reject(new Error('Failed retrieve the single game play'));
      }
    });
  }
}
