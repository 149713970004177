import { Component, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IEnvironmentConfig, PROJECT_ENV_CONFIG_TOKEN } from 'bp-angular-library';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

@Component({
  selector: 'ion-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public disableSidemenu: boolean = inject<IEnvironmentConfig<any>>(PROJECT_ENV_CONFIG_TOKEN)?.features?.frontendMode === 'surfpoint';

  constructor(private translate: TranslateService) {
    this.translate.addLangs(['sr', 'zh']);
    this.translate.setDefaultLang('sr');
    this.translate.use('sr');
  }
}
