/* eslint-disable no-async-promise-executor */
import { TranslateService } from '@ngx-translate/core';
import { StorageService, AuthService } from 'bp-angular-library';
import { STORAGE_KEYS } from 'bp-framework/dist/storage/storage.const';
import { Router, Routes } from '@angular/router';
import { EnvAdapterService } from '../env-specific/env-adapter.service';

export function appInitFactory(
  router: Router,
  storageService: StorageService,
  authService: AuthService,
  translateService: TranslateService,
  envAdapterService: EnvAdapterService
): () => Promise<boolean> {
  return () =>
    new Promise(async (resolve, reject) => {
      await storageService.initializeStorageService();
      await authService.handleAppInitialization();

      const lastLanguage: string = await storageService.getLocalItem(STORAGE_KEYS.langAndRegion);

      translateService.setDefaultLang(lastLanguage || 'sr');
      translateService.use(lastLanguage || 'sr');

      const appRoutes: Routes = envAdapterService.buildAllRoutes();
      router.resetConfig(appRoutes);

      resolve(true);
    });
}
