import { INavGroup } from 'bp-framework/dist/common/common.interface';

export const MAIN_ROUTE_PATHS = {
  notFound: 'not-found',
  login: 'login',
  home: 'home',
  landing: 'landing',
  betting: 'betting',
  bettingLive: 'betting-live',
  casino: 'casino',
  casinoLive: 'casino-live',
  virtualSports: 'virtual-sports',
  virtualGames: 'virtual-games',
  surfpoint: 'surfpoint'
};

export const PLAYER_ROUTE_ROOT_SEGMENT = 'player';

export const PLAYER_ROUTE_CHILD_SEGMENTS = {
  dashboard: 'dashboard',
  betSlips: 'bet-slips',
  transactions: 'transactions',
  details: 'details'
};

export const SIDEMENU_NAV_GROUPS: Partial<INavGroup>[] = [
  {
    path: 'home',
    label: 'Home',
    t9nKey: '',
    sortOrder: 0,
    icon: 'ph-house',
    children: []
  },
  {
    path: 'betting',
    label: 'Betting',
    t9nKey: '',
    sortOrder: 1,
    icon: 'ph-soccer-ball'
  },
  {
    path: 'betting-live',
    label: 'Live Betting',
    t9nKey: '',
    sortOrder: 2,
    icon: 'ph-soccer-ball'
  },
  {
    path: 'casino',
    label: 'Casino',
    t9nKey: '',
    sortOrder: 3,
    icon: 'ph-club'
  },
  {
    path: 'casino-live',
    label: 'Live Casino',
    t9nKey: '',
    sortOrder: 4,
    icon: 'ph-club'
  }
];
